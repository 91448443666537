import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import './Assets/css/video.css'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import VideoConference from './Containers/Videos/VideoConference'

const Layout = (component) => {
  return <div className="App">
            { component }
        </div>;
};

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Switch>
            <Route path="/" exact render={props => <VideoConference {...props} />} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
    );
  }
}

export default App;
