import React, { Component } from 'react';
import {Container, Row, Col, CustomInput} from 'reactstrap';
import {MdChatBubble} from 'react-icons/md';
import {MdPhoneInTalk} from 'react-icons/md'
import {FaMicrophone} from 'react-icons/fa';
import {FaUserAlt} from 'react-icons/fa';
import {MdScreenShare} from 'react-icons/md';
import {FaPlus} from 'react-icons/fa';
import Switch from "react-switch";

const chats = [
    {
        name : 'Soma',
        message : 'Hi Guys!'
    },
    {
        name : 'Jeremiah',
        message : "Let's increase scopre this way"
    },
    {
        name : 'Damas',
        message : 'I dunno man'
    },
    {
        name : 'Mario',
        message : 'Nooo....'
    },
    {
        name : 'Soma',
        message : 'After EA'
    },
    {
        name : 'Soma',
        message : '....'
    },
    {
        name : 'Jeremiah',
        message : "Let's try soon"
    },
    {
        name : 'Mario',
        message : "Hmmm , it will make me redo stuff"
    },
]

class VideoConference extends Component {

    constructor() {
        super();
        this.state = { checked: false };
        this.handleChange = this.handleChange.bind(this);
    }
     
    handleChange(checked) {
        this.setState({ checked });
    }

    componentDidMount(){
        // window.switchVideo()
    }

    handleSwitch(){
        window.switchVideo()
    }
    

    render(){
        return(
            <div>
                <Row>
                    <Col md="12" className="conference-header">
                        <div className="conference-logo">KOVID</div>
                        <div className="conference-logo-chat"><MdChatBubble/></div>
                    </Col>
                </Row>
                <Row className="conference-container">
                    <Col md="2" className="conference-group-container">
                        <Row>
                            <Col>
                                <div className="conference-server-container">
                                    <div className="conference-server-name">StairwayGames</div>
                                    <div className="conference-server-switch">
                                        <Switch 
                                            onChange={this.handleChange} 
                                            checked={this.state.checked} 
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            height={15}
                                            width={27}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="conference-group-name">
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col" >
                                            # {'\u00A0'}everyone
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em', flexDirection:'row'}}>
                                        <div className="group-name col"># {'\u00A0'}3d</div>
                                        <div className="icon-microphone col">
                                            <FaMicrophone style={{float:"right"}}/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}code</div>
                                        <div className="icon-microphone col">
                                            <FaMicrophone style={{float:"right"}}/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}animation</div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}bug-peformance</div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}sound</div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}character-art</div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}coral-diving</div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}art</div>
                                    </div>
                                    <div className="row" style={{marginBottom:'0.3em'}}>
                                        <div className="group-name col"># {'\u00A0'}materials</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="7" className="conference-video-container">
                        <Container style={{marginTop:'1.7em'}}>
                            <Row style={{marginBottom:'1.2em'}} className="videorow">
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}} 
                                                     id="shareScreen">
                                                         {/* <MdScreenShare/> */}
                                                </div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body local-body transparent">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                {/* <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col> */}
                                {/* <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col> */}
                            </Row>
                            <Row style={{marginBottom:'1.2em'}}>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            <div className="video-name-wrapper">
                                                <div style={{marginRight:'15px'}}><MdPhoneInTalk/></div>
                                                <div style={{marginRight:'6px'}}>Soma</div>
                                                <div>
                                                    <FaUserAlt style={{width:'10px', height:'10px'}}/>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="card video-wrapper">
                                            <div className="card-body">
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md="3" className="conference-chat-container">
                        <div className="conference-chat">
                            {
                                chats.map((chat, index)=>
                                <div className="row" style={{marginBottom:'1em'}} key={index}>
                                    <div className="col-md-2">
                                        <div className="icon-profile">
                                            <div className="profile-container">
                                                <FaUserAlt style={{width:'18px', height:'18px'}}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-10" style={{fontSize:'13px'}}>
                                        <div style={{marginLeft:'1em'}}>
                                            <Row><b>{chat.name}</b></Row>
                                            <Row>{chat.message}</Row>
                                        </div>
                                    </div>
                                </div>
                                )
                            }
                        </div>
                        <div style={{position: 'absolute', bottom: 40, width:'90%'}}>
                            <div className="input-icons"> 
                                <FaPlus className="icon" style={{width:'18px', height:'18px'}}/>
                                <input className="input-field" type="text"/> 
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    
}

export default VideoConference;